import Blockly from 'blockly';

import { createPlusField } from '../../utils/blockly/plus';
import { createMinusField } from '../../utils/blockly/minus';


if (!Blockly.Extensions.isRegistered('join_mutator')) {
  const QUERY_JOIN_MUTATOR_MIXIN = {

    itemCount_: 0,
    customName_: '',

    saveExtraState: function () {
      return {
        'itemCount': this.itemCount_,
        'customName': this.customName_
      };
    },

    loadExtraState: function (state) {
      this.customName_ = state['customName'];
      this.updateShape_(state['itemCount']);
    },

    plus: function () {
      this.addPart_();
      this.updateMinus_();
    },

    minus: function () {
      if (this.itemCount_ == 0) {
        return;
      }
      this.removePart_();
      this.updateMinus_();
    },

    addPart_: function () {
      if (this.itemCount_ == 0) {
        if (this.getInput('EMPTY')) {
          this.removeInput('EMPTY');
        }
        this.topInput_ = this.appendValueInput('ADD' + this.itemCount_)
          .appendField(createPlusField(), 'PLUS')
      } else {
        this.appendValueInput('ADD' + this.itemCount_);
      }
      // Because item inputs are 0-index we decrement first, increment last.
      this.itemCount_++;
    },

    removePart_: function () {
      this.itemCount_--;
      if (this.itemCount_ < 1) {
        this.itemCount_ = 1;
        return;
      }
      
      this.removeInput('ADD' + this.itemCount_);
    },

    updateMinus_: function () {
      const minusField = this.getField('MINUS');
      if (!minusField && this.itemCount_ > 0) {
        this.topInput_.insertFieldAt(1, createMinusField(), 'MINUS');
      } else if (minusField && this.itemCount_ < 1) {
        this.topInput_.removeField('MINUS');
      }
    },

    updateShape_: function (targetCount) {
      while (this.itemCount_ < targetCount) {
        this.addPart_();
      }
      while (this.itemCount_ > targetCount) {
        this.removePart_();
      }
      this.updateMinus_();

      if (this.customName_ && this.topInput_) {
        this.topInput_.appendField(this.customName_);
      }
    },
  };

  const queryJoinHelper = function () {
    // Blockly.Extensions.apply('text_quotes', this, false);
    this.updateShape_(2);
  };

  Blockly.Extensions.registerMutator('join_mutator', QUERY_JOIN_MUTATOR_MIXIN, queryJoinHelper);
}

