var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.DecisionAlert,
            callback: function ($$v) {
              _vm.DecisionAlert = $$v
            },
            expression: "DecisionAlert",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass:
                "d-flex align-center justify-center flex-column w-100",
            },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100",
                },
                [
                  _c("h5", [
                    _vm._v(_vm._s(_vm.$t("decisionAlert.confirm")) + " "),
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "white--text",
                      on: { click: _vm.emitDecisionAlertCancelClicked },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "d-flex align-start px-6 py-8 pb-6 w-100" },
                [
                  _vm.DecisionAlertIcon === 1
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "font-32 mr-3 ",
                          attrs: { color: "primary" },
                        },
                        [_vm._v("mdi-delete-circle")]
                      )
                    : _vm._e(),
                  _vm.DecisionAlertIcon === 2
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "mr-3 font-32 primary--text",
                          attrs: { color: " " },
                        },
                        [_vm._v("mdi-clipboard-check")]
                      )
                    : _vm._e(),
                  _vm.DecisionAlertIcon === 3
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "mr-3 font-32 primary--text ",
                          attrs: { color: " ", small: "" },
                        },
                        [_vm._v("mdi-checkbox-marked-circle-outline")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column w-100" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "text-subtitle-1 pa-0" },
                        [_vm._v(" " + _vm._s(_vm.DecisionAlertTitle) + " ")]
                      ),
                      _c(
                        "v-card-text",
                        {
                          staticClass:
                            " text-subtitle-1 pa-0 d-flex w-100 flex-column",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.DecisionAlertMessage) + " "
                            ),
                          ]),
                          _vm.DecisionAlertData.from == "adsmanager-toggle"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center checkbox-outer  w-100 px-6 justify-start",
                                },
                                [
                                  _c("v-checkbox", {
                                    attrs: { label: "" },
                                    model: {
                                      value: _vm.checkbox,
                                      callback: function ($$v) {
                                        _vm.checkbox = $$v
                                      },
                                      expression: "checkbox",
                                    },
                                  }),
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        " text-subtitle-2 pa-0 greybase--text ml-2 mt-3",
                                    },
                                    [
                                      _vm._v(
                                        " click here to hide the confirmation dialog next time. "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                {
                  staticClass:
                    "d-flex justify-end align-center w-100 pb-6  px-6",
                },
                [
                  !_vm.onlyMessage
                    ? _c(
                        "v-btn",
                        {
                          attrs: { width: "100px", color: " " },
                          on: { click: _vm.emitDecisionAlertCancelClicked },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("decisionAlert.cancel")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-6",
                      attrs: { color: "primary" },
                      on: { click: _vm.emitDecisionAlertOkClicked },
                    },
                    [_vm._v(" " + _vm._s(_vm.okButtonMessage) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }