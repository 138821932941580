var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "v-fixed-right result-tab",
          staticStyle: { "z-index": "999999 !important" },
          attrs: { app: "", fixed: "", temporary: "", right: "", width: "500" },
          model: {
            value: _vm.snapshotDialog,
            callback: function ($$v) {
              _vm.snapshotDialog = $$v
            },
            expression: "snapshotDialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { outlined: "", height: "100%" } },
            [
              _c(
                "div",
                { staticClass: "sticky-header" },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "headline d-flex justify-space-between px-5 py-3 mb-0 br-bottom ",
                    },
                    [
                      _c("h5", { staticClass: "text-h6" }, [
                        _vm._v("Test Results"),
                      ]),
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function ($event) {
                              _vm.snapshotDialog = false
                            },
                          },
                        },
                        [_vm._v("mdi-close")]
                      ),
                    ],
                    1
                  ),
                  !_vm.snapshotLoading
                    ? [
                        _c(
                          "v-tabs",
                          {
                            staticClass: "trigger-tabs px-6 ",
                            attrs: { height: "45px" },
                            model: {
                              value: _vm.resultTab,
                              callback: function ($$v) {
                                _vm.resultTab = $$v
                              },
                              expression: "resultTab",
                            },
                          },
                          _vm._l(_vm.resultTabItems, function (item) {
                            return _c("v-tab", { key: item }, [
                              _vm._v(" " + _vm._s(item) + " "),
                            ])
                          }),
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm.snapshotLoading
                ? [
                    _c(
                      "v-row",
                      { staticClass: "testRun" },
                      [
                        _c(
                          "v-col",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: { cols: "6" },
                          },
                          [
                            _c("p", [_vm._v("Test Run Id : ")]),
                            _c("p", [_vm._v(_vm._s(_vm.testRunId))]),
                          ]
                        ),
                        _c("v-col", { attrs: { cols: "3" } }, [
                          _c("p", [_vm._v("Elapsed time: ")]),
                          _c("p", [_vm._v(_vm._s(_vm.sarElapsedTime))]),
                        ]),
                        _c(
                          "v-col",
                          { attrs: { cols: "1" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", color: "primarydark" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "mr-1 font-22 icon-hover",
                                                  attrs: {
                                                    color: "primary",
                                                    dark: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.copyJSON()
                                                    },
                                                  },
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" mdi-content-copy ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3259594605
                                ),
                              },
                              [_c("span", [_vm._v("Copy Log")])]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-column",
                        staticStyle: { "margin-left": "20px" },
                      },
                      [
                        _vm._l(_vm.testRunProgressText, function (msg) {
                          return _c(
                            "p",
                            { staticClass: "greybase--text ma-0 mt-1" },
                            [_vm._v(_vm._s(msg))]
                          )
                        }),
                        _c("v-divider", { staticClass: "mt-4" }),
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
              !_vm.snapshotLoading
                ? [
                    _c(
                      "v-tabs-items",
                      {
                        model: {
                          value: _vm.resultTab,
                          callback: function ($$v) {
                            _vm.resultTab = $$v
                          },
                          expression: "resultTab",
                        },
                      },
                      [
                        _c(
                          "v-tab-item",
                          [
                            _c(
                              "v-card",
                              { attrs: { flat: "" } },
                              [
                                _c("v-switch", {
                                  staticClass: "ml-2 expand-btn",
                                  attrs: {
                                    label: "Expand All",
                                    color: "primary",
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.expand1,
                                    callback: function ($$v) {
                                      _vm.expand1 = $$v
                                    },
                                    expression: "expand1",
                                  },
                                }),
                                _c("json-viewer", {
                                  key: _vm.expandCount1 + "-json-viewer",
                                  attrs: {
                                    "expand-depth": _vm.expandCount1,
                                    value: _vm.humanJson,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-tab-item",
                          [
                            _c(
                              "v-card",
                              { attrs: { flat: "" } },
                              [
                                _c("v-switch", {
                                  staticClass: "ml-2 expand-btn",
                                  attrs: {
                                    label: "Expand All",
                                    color: "primary",
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.expand2,
                                    callback: function ($$v) {
                                      _vm.expand2 = $$v
                                    },
                                    expression: "expand2",
                                  },
                                }),
                                _c("json-viewer", {
                                  key: _vm.expandCount2 + "json-viewer-2",
                                  attrs: {
                                    "expand-depth": _vm.expandCount2,
                                    value: _vm.jsonData,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "v-card-actions",
                { staticClass: "justify-space-between pa-3" },
                [_c("div", { staticClass: "right-action-btn d-flex" })]
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("v-app", { staticClass: "scriptedRuleBody" }, [
        _c(
          "div",
          { staticClass: "inner-container only-heading-part mt-0 " },
          [
            _c(
              "v-container",
              {
                staticClass: "v-main-content only-simple-content pt-0",
                attrs: { fluid: "" },
              },
              [
                [
                  _c(
                    "div",
                    { attrs: { id: "app" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass:
                            "transparent page-header-with-banner rounded-lg px-0 h-100 pa-4 pb-8 pt-0 extra-padding v-card v-sheet theme--light",
                          attrs: { elevation: "0" },
                        },
                        [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "primarybase--text h5--text my-3 mb-6",
                            },
                            [_vm._v("Create Scripted Rule")]
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass: "transparent ",
                              attrs: { elevation: "0" },
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: " " },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: " toggle-btn",
                                      attrs: { cols: "12", lg: "6", sm: "12" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          class:
                                            _vm.modeToggle === "blocklyeditor"
                                              ? ["px-8", "bg-active"]
                                              : "px-8",
                                          attrs: {
                                            outlined: "",
                                            plain: _vm.lockBlockly,
                                            color: "primarydark",
                                            rounded: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.confirmSarModeChange(
                                                "blocklyeditor"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm.modeToggle === "blocklyeditor"
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { color: "primary" },
                                                },
                                                [_vm._v("mdi-check")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.lockBlockly
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { color: "primary" },
                                                },
                                                [_vm._v("mdi-lock-outline")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" Automation Blocks "),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "px-8",
                                          class:
                                            _vm.modeToggle === "jseditor"
                                              ? ["px-8", "bg-active"]
                                              : "px-8",
                                          attrs: {
                                            outlined: "",
                                            color: "primarydark",
                                            rounded: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.confirmSarModeChange(
                                                "jseditor"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm.modeToggle === "jseditor"
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    color: "primarydark",
                                                  },
                                                },
                                                [_vm._v("mdi-check")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" JavaScript Editor "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex justify-end",
                                      attrs: { cols: "12", lg: "6", sm: "12" },
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass:
                                          "no-field-details scripted-filter mt-0",
                                        attrs: {
                                          items: _vm.templateItems,
                                          height: "40",
                                          label: "Select Template",
                                          outlined: "",
                                          dense: "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.applyTemplate($event)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        " py-0 d-flex align-center justify-space-between kai-btn-wrap",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "sm-show",
                                          attrs: {
                                            color: "primary",
                                            depressed: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showSmallPopup = true
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [_vm._v("mdi-plus")]),
                                          _vm._v("Add Actions "),
                                        ],
                                        1
                                      ),
                                      _vm.showpopup
                                        ? _c(
                                            "div",
                                            { staticClass: "button-wrapper" },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    " close font-22 ",
                                                  attrs: { color: " " },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showSmallPopup = false
                                                    },
                                                  },
                                                },
                                                [_vm._v("mdi-close")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-2 mb-2",
                                                  attrs: {
                                                    small: "",
                                                    disabled:
                                                      _vm.superGlobals.length >
                                                      0,
                                                    text: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addSuperGlobal()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                    },
                                                    [_vm._v("mdi-plus")]
                                                  ),
                                                  _vm._v("Add Inputs "),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-2 mb-2",
                                                  attrs: {
                                                    text: "",
                                                    small: "",
                                                    color: "primary",
                                                    depressed: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addEditor(
                                                        "trigger"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-plus"),
                                                  ]),
                                                  _vm._v(" Add Trigger "),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    disabled: _vm.triggerExists,
                                                    bottom: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "span",
                                                              _vm._g({}, on),
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2 mb-2",
                                                                    attrs: {
                                                                      disabled: !_vm.triggerExists,
                                                                      text: "",
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                      depressed:
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addEditor(
                                                                          "campaign"
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-plus"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Add Campaign Action "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2663012143
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "A trigger is required for creating ASA actions."
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    disabled: _vm.triggerExists,
                                                    bottom: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "span",
                                                              _vm._g({}, on),
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2 mb-2",
                                                                    attrs: {
                                                                      disabled: !_vm.triggerExists,
                                                                      text: "",
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                      depressed:
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addEditor(
                                                                          "adgroup"
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-plus"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Add Adgroup Action "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3572985263
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "A trigger is required for creating ASA actions."
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    disabled: _vm.triggerExists,
                                                    bottom: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "span",
                                                              _vm._g({}, on),
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2 mb-2",
                                                                    attrs: {
                                                                      disabled: !_vm.triggerExists,
                                                                      text: "",
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                      depressed:
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addEditor(
                                                                          "keyword"
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-plus"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Add Keyword Action "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    223903759
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "A trigger is required for creating ASA actions."
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-2 mb-2",
                                                  attrs: {
                                                    text: "",
                                                    small: "",
                                                    color: "primary",
                                                    depressed: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addEditor(
                                                        "onetime"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-plus"),
                                                  ]),
                                                  _vm._v(
                                                    " Add One-Time Action "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "min-w-fit-content" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-1 mb-0",
                                              attrs: {
                                                width: "120",
                                                color: "primary",
                                                depressed: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.validateActions()
                                                },
                                              },
                                            },
                                            [_vm._v(" Validate ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "primarydark--text ",
                                              attrs: {
                                                width: "120",
                                                color: "secondary",
                                                depressed: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.initiateTestRun()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    color:
                                                                      "primarydark",
                                                                    small: "",
                                                                  },
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [_vm._v("mdi-play")]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Run the rule for Debugging"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.enableSARLiveRun
                                                      ? "Test Run v2"
                                                      : "Test Run"
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              /* modeToggle === 'jseditor' && */ _vm
                                .superGlobalsModified.length
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "br-8 br h-100 ",
                                      attrs: { elevation: "0" },
                                    },
                                    [
                                      _c(
                                        "v-expansion-panels",
                                        {
                                          staticClass: "scripted-rule-pannel",
                                          attrs: {
                                            accordion: "",
                                            elevation: "0",
                                            multiple: "",
                                          },
                                          model: {
                                            value: _vm.inputPanel,
                                            callback: function ($$v) {
                                              _vm.inputPanel = $$v
                                            },
                                            expression: "inputPanel",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-expansion-panel",
                                            [
                                              _c(
                                                "v-expansion-panel-header",
                                                {
                                                  staticClass:
                                                    "mb-0 d-flex align-center justify-space-between mt-0 pt-2 pb-6 trigger-cust-icon greyxxlight primary",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "primarybase--text body--text-1 mt-4",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Input variables "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-center edit-delete-icon",
                                                  staticStyle: {},
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "cursor-pointer",
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            _vm.superGlobals = []
                                                                          },
                                                                        },
                                                                      },
                                                                      "v-icon",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-delete"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        2136730410
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Delete"),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-expansion-panel-content",
                                                {
                                                  staticClass:
                                                    "scriptedRule-outer",
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "br-8    h-100 w-100 pa-6 input-var overflow-y-auto",
                                                      attrs: {
                                                        elevation: "0",
                                                        "max-height": "38vh",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-form",
                                                        {
                                                          ref: "inputFormRef",
                                                          model: {
                                                            value:
                                                              _vm.inputFormValid,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.inputFormValid = $$v
                                                            },
                                                            expression:
                                                              "inputFormValid",
                                                          },
                                                        },
                                                        [
                                                          _vm._l(
                                                            _vm.superGlobals,
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "v-row",
                                                                {
                                                                  key:
                                                                    index +
                                                                    "-superglobal-inputs",
                                                                  staticClass:
                                                                    "d-flex align-start justify-start mb-3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "py-0",
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        md: "3",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            " ",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Input Name"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "pt-0 v-sel-custom mt-0 ",
                                                                          attrs: {
                                                                            rules: [
                                                                              function (
                                                                                v
                                                                              ) {
                                                                                return (
                                                                                  v !=
                                                                                    "" ||
                                                                                  "Please enter a value"
                                                                                )
                                                                              },
                                                                              function (
                                                                                v
                                                                              ) {
                                                                                return (
                                                                                  /^[a-zA-Z0-9 ]+$/.test(
                                                                                    v
                                                                                  ) ||
                                                                                  "Name must be alphanumeric"
                                                                                )
                                                                              },
                                                                              _vm.checkUnique(
                                                                                index
                                                                              ),
                                                                            ],
                                                                            label:
                                                                              " ",
                                                                            required:
                                                                              "",
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              item.inputName,
                                                                            callback: function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "inputName",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "item.inputName",
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "py-0",
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        md: "3",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "Description"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "pt-0 v-sel-custom mt-0 ",
                                                                          attrs: {
                                                                            rules: [
                                                                              function (
                                                                                v
                                                                              ) {
                                                                                return (
                                                                                  v !=
                                                                                    "" ||
                                                                                  "Please enter a value"
                                                                                )
                                                                              },
                                                                            ],
                                                                            label:
                                                                              "",
                                                                            required:
                                                                              "",
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              item.desc,
                                                                            callback: function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "desc",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "item.desc",
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "py-0",
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        md: "3",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "Value"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "pt-0 v-sel-custom mt-0 ",
                                                                          attrs: {
                                                                            rules:
                                                                              _vm.checkNumAndAlpha,
                                                                            label:
                                                                              "",
                                                                            required:
                                                                              "",
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              item.value,
                                                                            callback: function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "value",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "item.value",
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex align-center justify-space-between py-0",
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        md: "3",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex flex-column   my-1 w-100 justify-space-between",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              staticClass:
                                                                                " ",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Usage"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex w-fit-content justify-space-between ",
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .superGlobalsModified[
                                                                                index
                                                                              ]
                                                                                .usage
                                                                                ? _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          "",
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function (
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "caption--text mt-1 text-overflow cursor-default max-200",
                                                                                                      },
                                                                                                      "span",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        _vm
                                                                                                          .superGlobalsModified[
                                                                                                          index
                                                                                                        ]
                                                                                                          .usage
                                                                                                      ) +
                                                                                                        " "
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            },
                                                                                          },
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      ),
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm
                                                                                                .superGlobalsModified[
                                                                                                index
                                                                                              ]
                                                                                                .usage
                                                                                            ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .superGlobalsModified[
                                                                                index
                                                                              ]
                                                                                .usage
                                                                                ? _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          "",
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function (
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "cursor-pointer ml-2",
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "primary",
                                                                                                          size:
                                                                                                            "medium",
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.copyToClipboard(
                                                                                                              _vm
                                                                                                                .superGlobalsModified[
                                                                                                                index
                                                                                                              ]
                                                                                                                .usage
                                                                                                            )
                                                                                                          },
                                                                                                        },
                                                                                                      },
                                                                                                      "v-icon",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "mdi-content-copy"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            },
                                                                                          },
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      ),
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Copy to clipboard"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              "",
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function (
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  return [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            staticClass:
                                                                                              "cursor-pointer ml-2",
                                                                                            attrs: {
                                                                                              color:
                                                                                                "primary",
                                                                                            },
                                                                                            on: {
                                                                                              click: function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.superGlobals.splice(
                                                                                                  index,
                                                                                                  1
                                                                                                )
                                                                                              },
                                                                                            },
                                                                                          },
                                                                                          "v-icon",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-delete"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Delete"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mr-2  primary--text cursor-pointer",
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.addSuperGlobal()
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-plus"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                "Add Inputs "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-card",
                                {
                                  staticClass: "br-8 br h-100 ",
                                  attrs: { elevation: "0" },
                                },
                                [
                                  _c(
                                    "v-expansion-panels",
                                    {
                                      staticClass: "scripted-rule-pannel",
                                      attrs: {
                                        accordion: "",
                                        elevation: "0",
                                        multiple: "",
                                        value: _vm.scriptedExpansionValue,
                                      },
                                    },
                                    _vm._l(
                                      _vm.scriptedAutomationsModel
                                        .automationSteps,
                                      function (item, index) {
                                        return _c(
                                          "v-expansion-panel",
                                          {
                                            key: index + "-sar-expansion-panel",
                                            class:
                                              item.type !== "trigger" &&
                                              item.type !== "trigger_blockly"
                                                ? [
                                                    "white--text",
                                                    "action-panel",
                                                  ]
                                                : "",
                                          },
                                          [
                                            _c(
                                              "v-expansion-panel-header",
                                              {
                                                staticClass:
                                                  "mb-0 d-flex align-center justify-space-between mt-0 pt-2 pb-9 trigger-cust-icon greyxxlight primary",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "primarybase--text body--text-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Step " +
                                                        _vm._s(index + 1) +
                                                        " : " +
                                                        _vm._s(item.label) +
                                                        " "
                                                    ),
                                                    item.isValid &&
                                                    _vm.anActionWithoutTrigger &&
                                                    ![
                                                      "trigger",
                                                      "trigger_blockly",
                                                      "onetime",
                                                      "onetime_blockly",
                                                    ].includes(item.type)
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color:
                                                                "primarydark",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "red--text font-22",
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-alert-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "A trigger is required before ASA actions."
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !item.isValid
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              color:
                                                                "primarydark",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "red--text font-22",
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-alert-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            ![
                                                              "campaign_blockly",
                                                              "adgroup_blockly",
                                                              "keyword_blockly",
                                                              "onetime_blockly",
                                                              "trigger_blockly",
                                                            ].includes(
                                                              item.type
                                                            )
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "Errors found in script. Please fix to continue."
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            [
                                                              "campaign_blockly",
                                                              "adgroup_blockly",
                                                              "keyword_blockly",
                                                              "onetime_blockly",
                                                              "trigger_blockly",
                                                            ].includes(
                                                              item.type
                                                            )
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "There is an error with your Blockly code."
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "mt-0 font-13 edit-name primary--text d-flex align-start flex-row",
                                                staticStyle: {},
                                              },
                                              [
                                                _c("v-text-field", {
                                                  class: item.titleEditable
                                                    ? item.type === "trigger" ||
                                                      item.type ===
                                                        "trigger_blockly"
                                                      ? [
                                                          "edit-trigger-inactive",
                                                          " edit-trigger-active",
                                                          " primary--text",
                                                        ]
                                                      : [
                                                          "edit-inactive",
                                                          " edit-active",
                                                          " white--text",
                                                        ]
                                                    : item.type === "trigger" ||
                                                      item.type ===
                                                        "trigger_blockly"
                                                    ? " edit-trigger-active"
                                                    : "edit-active",
                                                  style: {
                                                    width:
                                                      item.title.length * 9 +
                                                      "px",
                                                  },
                                                  attrs: {
                                                    required: "",
                                                    solo: "",
                                                    readonly: !item.titleEditable,
                                                  },
                                                  model: {
                                                    value: item.title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "title",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.title",
                                                  },
                                                }),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              !item.titleEditable
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          class:
                                                                            item.type ===
                                                                              "trigger" ||
                                                                            item.type ===
                                                                              "trigger_blockly"
                                                                              ? [
                                                                                  "grey--text edit-pencile font-18",
                                                                                ]
                                                                              : [
                                                                                  "grey--text edit-pencile font-18",
                                                                                ],
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              item.titleEditable = true
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pencil-outline"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [_c("span", [_vm._v("Edit")])]
                                                ),
                                                item.titleEditable
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        class:
                                                          item.type ===
                                                            "trigger" ||
                                                          item.type ===
                                                            "trigger_blockly"
                                                            ? [
                                                                "primary--text edit-check font-22",
                                                              ]
                                                            : [
                                                                "primary--text edit-check font-22",
                                                              ],
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            item.titleEditable = false
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("mdi-check")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-flex align-center justify-center edit-icon",
                                                staticStyle: {},
                                              },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _vm.showMoveUp(
                                                                item,
                                                                index
                                                              )
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          class:
                                                                            item.type ===
                                                                              "trigger" ||
                                                                            item.type ===
                                                                              "trigger_blockly"
                                                                              ? [
                                                                                  "primary--text mr-2",
                                                                                ]
                                                                              : [
                                                                                  "primary--text mr-2",
                                                                                ],
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.moveEditor(
                                                                                index,
                                                                                -1
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-arrow-up "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Move Up"),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _vm.showMoveDown(
                                                                item,
                                                                index
                                                              )
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          class:
                                                                            item.type ===
                                                                              "trigger" ||
                                                                            item.type ===
                                                                              "trigger_blockly"
                                                                              ? [
                                                                                  "primary--text mr-2",
                                                                                ]
                                                                              : [
                                                                                  "primary--text mr-2",
                                                                                ],
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.moveEditor(
                                                                                index,
                                                                                1
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-arrow-down "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Move Down"),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              index
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          class:
                                                                            item.type ===
                                                                              "trigger" ||
                                                                            item.type ===
                                                                              "trigger_blockly"
                                                                              ? [
                                                                                  "primary--text mr-2",
                                                                                ]
                                                                              : [
                                                                                  "primary--text mr-2",
                                                                                ],
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteScriptedArStep(
                                                                                index
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete-outline"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Delete"),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-expansion-panel-content",
                                              {
                                                staticClass:
                                                  "scriptedRule-outer",
                                              },
                                              [
                                                ![
                                                  "campaign_blockly",
                                                  "adgroup_blockly",
                                                  "keyword_blockly",
                                                  "onetime_blockly",
                                                  "trigger_blockly",
                                                ].includes(item.type)
                                                  ? _c(
                                                      "v-card",
                                                      {
                                                        staticClass:
                                                          "br-8  br overflow-y-scroll h-100 scriptedRuleLeft",
                                                        attrs: {
                                                          elevation: "0",
                                                          "max-height": "38vh",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            staticClass:
                                                              "scripted-tab",
                                                            attrs: {
                                                              elevation: "0",
                                                            },
                                                          },
                                                          [
                                                            item.type ===
                                                            "trigger"
                                                              ? [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "sticky-header-h",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-tabs",
                                                                        {
                                                                          staticClass:
                                                                            "trigger-tabs ",
                                                                          attrs: {
                                                                            height:
                                                                              "45px",
                                                                            "center-active":
                                                                              "",
                                                                            "show-arrows":
                                                                              "",
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .scriptedtab[
                                                                                index
                                                                              ],
                                                                            callback: function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.scriptedtab,
                                                                                index,
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "scriptedtab[index]",
                                                                          },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.trigVarTabs,
                                                                          function (
                                                                            item
                                                                          ) {
                                                                            return _c(
                                                                              "v-tab",
                                                                              {
                                                                                key:
                                                                                  item +
                                                                                  "-trigVarTabs",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      item
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "mt-3 mx-4 var-search",
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            dense:
                                                                              "",
                                                                            placeholder:
                                                                              "Search",
                                                                            height:
                                                                              "35px",
                                                                            "prepend-inner-icon":
                                                                              "mdi-magnify",
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.searchTriggerVars,
                                                                            callback: function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.searchTriggerVars = $$v
                                                                            },
                                                                            expression:
                                                                              "searchTriggerVars",
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-tabs-items",
                                                                    {
                                                                      staticClass:
                                                                        "scripted-search ",
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .scriptedtab[
                                                                            index
                                                                          ],
                                                                        callback: function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.scriptedtab,
                                                                            index,
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "scriptedtab[index]",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-tab-item",
                                                                        [
                                                                          _c(
                                                                            "v-card",
                                                                            {
                                                                              attrs: {
                                                                                flat:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list",
                                                                                {
                                                                                  staticClass:
                                                                                    "pt-0 mx-4",
                                                                                  attrs: {
                                                                                    dense:
                                                                                      "",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-list-item-group",
                                                                                    {
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary",
                                                                                      },
                                                                                    },
                                                                                    _vm._l(
                                                                                      _vm.campaignTriggerVar,
                                                                                      function (
                                                                                        item,
                                                                                        i
                                                                                      ) {
                                                                                        return _c(
                                                                                          "v-list-item",
                                                                                          {
                                                                                            key:
                                                                                              i +
                                                                                              "-campaignTriggerVar",
                                                                                            staticClass:
                                                                                              "font-14",
                                                                                            attrs: {
                                                                                              "min-height":
                                                                                                "35px",
                                                                                            },
                                                                                            on: {
                                                                                              click: function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.insertFromNavigator(
                                                                                                  "campaign." +
                                                                                                    item,
                                                                                                  index
                                                                                                )
                                                                                              },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-list-item-icon",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-img",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "ma-0",
                                                                                                    attrs: {
                                                                                                      rounded:
                                                                                                        "",
                                                                                                      src: require("../assets/images/var.svg"),
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                            _c(
                                                                                              "v-list-item-content",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-list-item-title",
                                                                                                  {
                                                                                                    domProps: {
                                                                                                      textContent: _vm._s(
                                                                                                        item
                                                                                                      ),
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-tab-item",
                                                                        {
                                                                          staticClass:
                                                                            "scripted-search",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-card",
                                                                            {
                                                                              attrs: {
                                                                                flat:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list",
                                                                                {
                                                                                  staticClass:
                                                                                    "pt-0 mx-4",
                                                                                  attrs: {
                                                                                    dense:
                                                                                      "",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-list-item-group",
                                                                                    {
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary",
                                                                                      },
                                                                                    },
                                                                                    _vm._l(
                                                                                      _vm.adgroupTriggerVar,
                                                                                      function (
                                                                                        item,
                                                                                        i
                                                                                      ) {
                                                                                        return _c(
                                                                                          "v-list-item",
                                                                                          {
                                                                                            key:
                                                                                              i +
                                                                                              "-adgroupTriggerVar",
                                                                                            staticClass:
                                                                                              "font-14",
                                                                                            attrs: {
                                                                                              "min-height":
                                                                                                "35px",
                                                                                            },
                                                                                            on: {
                                                                                              click: function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.insertFromNavigator(
                                                                                                  "adgroup." +
                                                                                                    item,
                                                                                                  index
                                                                                                )
                                                                                              },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-list-item-icon",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-img",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "ma-0",
                                                                                                    attrs: {
                                                                                                      rounded:
                                                                                                        "",
                                                                                                      src: require("../assets/images/var.svg"),
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                            _c(
                                                                                              "v-list-item-content",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-list-item-title",
                                                                                                  {
                                                                                                    domProps: {
                                                                                                      textContent: _vm._s(
                                                                                                        item
                                                                                                      ),
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-tab-item",
                                                                        [
                                                                          _c(
                                                                            "v-card",
                                                                            {
                                                                              attrs: {
                                                                                flat:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list",
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-4 pt-0",
                                                                                  attrs: {
                                                                                    dense:
                                                                                      "",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-list-item-group",
                                                                                    {
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary",
                                                                                      },
                                                                                    },
                                                                                    _vm._l(
                                                                                      _vm.keywordTriggerVar,
                                                                                      function (
                                                                                        item,
                                                                                        i
                                                                                      ) {
                                                                                        return _c(
                                                                                          "v-list-item",
                                                                                          {
                                                                                            key:
                                                                                              i +
                                                                                              "-keywordTriggerVar",
                                                                                            staticClass:
                                                                                              "font-14",
                                                                                            attrs: {
                                                                                              "min-height":
                                                                                                "35px",
                                                                                            },
                                                                                            on: {
                                                                                              click: function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.insertFromNavigator(
                                                                                                  "keyword." +
                                                                                                    item,
                                                                                                  index
                                                                                                )
                                                                                              },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-list-item-icon",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-img",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "ma-0",
                                                                                                    attrs: {
                                                                                                      rounded:
                                                                                                        "",
                                                                                                      src: require("../assets/images/var.svg"),
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                            _c(
                                                                                              "v-list-item-content",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-list-item-title",
                                                                                                  {
                                                                                                    domProps: {
                                                                                                      textContent: _vm._s(
                                                                                                        item
                                                                                                      ),
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              : _vm._e(),
                                                            item.type !==
                                                            "trigger"
                                                              ? [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "sticky-header-h",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-tabs",
                                                                        {
                                                                          staticClass:
                                                                            " ",
                                                                          attrs: {
                                                                            height:
                                                                              "45px",
                                                                            "center-active":
                                                                              "",
                                                                            "show-arrows":
                                                                              "",
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .scriptedtab[
                                                                                index
                                                                              ],
                                                                            callback: function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.scriptedtab,
                                                                                index,
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "scriptedtab[index]",
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-tabs-slider",
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "primary",
                                                                                width:
                                                                                  "50%",
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._l(
                                                                            _vm.scripteditems,
                                                                            function (
                                                                              item
                                                                            ) {
                                                                              return _c(
                                                                                "v-tab",
                                                                                {
                                                                                  key:
                                                                                    item +
                                                                                    "-scripteditems",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        item
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "mt-3 var-search mx-4",
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            dense:
                                                                              "",
                                                                            placeholder:
                                                                              "Search",
                                                                            "prepend-inner-icon":
                                                                              "mdi-magnify",
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.searchVars,
                                                                            callback: function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.searchVars = $$v
                                                                            },
                                                                            expression:
                                                                              "searchVars",
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-tabs-items",
                                                                    {
                                                                      staticClass:
                                                                        "scripted-search",
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .scriptedtab[
                                                                            index
                                                                          ],
                                                                        callback: function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.scriptedtab,
                                                                            index,
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "scriptedtab[index]",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-tab-item",
                                                                        [
                                                                          _c(
                                                                            "v-card",
                                                                            {
                                                                              attrs: {
                                                                                flat:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list",
                                                                                {
                                                                                  staticClass:
                                                                                    "pt-0 mx-4",
                                                                                  attrs: {
                                                                                    dense:
                                                                                      "",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-list-item-group",
                                                                                    {
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary",
                                                                                      },
                                                                                    },
                                                                                    _vm._l(
                                                                                      _vm.getFiltFuncList(
                                                                                        item.type
                                                                                      ),
                                                                                      function (
                                                                                        item,
                                                                                        i
                                                                                      ) {
                                                                                        return _c(
                                                                                          "v-list-item",
                                                                                          {
                                                                                            key:
                                                                                              i +
                                                                                              "-getFiltFuncList",
                                                                                            staticClass:
                                                                                              "font-14",
                                                                                            attrs: {
                                                                                              "min-height":
                                                                                                "35px",
                                                                                            },
                                                                                            on: {
                                                                                              click: function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.insertFromNavigator(
                                                                                                  item,
                                                                                                  index
                                                                                                )
                                                                                              },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-list-item-icon",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-img",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "ma-0",
                                                                                                    attrs: {
                                                                                                      rounded:
                                                                                                        "",
                                                                                                      src: require("../assets/images/fun.svg"),
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                            _c(
                                                                                              "v-list-item-content",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-list-item-title",
                                                                                                  {
                                                                                                    domProps: {
                                                                                                      textContent: _vm._s(
                                                                                                        item
                                                                                                      ),
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-tab-item",
                                                                        [
                                                                          _c(
                                                                            "v-card",
                                                                            {
                                                                              attrs: {
                                                                                flat:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list",
                                                                                {
                                                                                  attrs: {
                                                                                    dense:
                                                                                      "",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-list-item-group",
                                                                                    {
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary",
                                                                                      },
                                                                                    },
                                                                                    _vm._l(
                                                                                      _vm.getFiltVarList(
                                                                                        item.type
                                                                                      ),
                                                                                      function (
                                                                                        item,
                                                                                        i
                                                                                      ) {
                                                                                        return _c(
                                                                                          "v-list-item",
                                                                                          {
                                                                                            key:
                                                                                              i +
                                                                                              "-getFiltVarList",
                                                                                            staticClass:
                                                                                              "font-14",
                                                                                            attrs: {
                                                                                              "min-height":
                                                                                                "35px",
                                                                                            },
                                                                                            on: {
                                                                                              click: function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.insertFromNavigator(
                                                                                                  item,
                                                                                                  index
                                                                                                )
                                                                                              },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-list-item-icon",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-img",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "ma-0",
                                                                                                    attrs: {
                                                                                                      rounded:
                                                                                                        "",
                                                                                                      src: require("../assets/images/var.svg"),
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                            _c(
                                                                                              "v-list-item-content",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-list-item-title",
                                                                                                  {
                                                                                                    domProps: {
                                                                                                      textContent: _vm._s(
                                                                                                        item
                                                                                                      ),
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              : _vm._e(),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-card",
                                                  {
                                                    class: _vm.jsExpanded
                                                      ? [
                                                          "scriptedRuleRight",
                                                          "pa-2 ",
                                                          "pr-0",
                                                        ]
                                                      : [
                                                          "scriptedRuleRight",
                                                          " ",
                                                          "pa-2",
                                                          "pr-0",
                                                        ],
                                                    attrs: { elevation: "0" },
                                                  },
                                                  [
                                                    item.type === "trigger"
                                                      ? [
                                                          _c("TriggerEditor", {
                                                            staticClass: "pr-2",
                                                            attrs: {
                                                              template:
                                                                item.template,
                                                              insertFromNavigator:
                                                                item.insertFromNavigator,
                                                              triggerVarMap:
                                                                _vm.triggerVarMap,
                                                              superGlobals: [].concat(
                                                                _vm.superGlobalsModified.map(
                                                                  function (e) {
                                                                    return (
                                                                      "@" +
                                                                      e.usage
                                                                    )
                                                                  }
                                                                )
                                                              ),
                                                            },
                                                            on: {
                                                              valid: function (
                                                                $event
                                                              ) {
                                                                return _vm.getEditorValues(
                                                                  $event,
                                                                  item,
                                                                  item.type,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      : _vm._e(),
                                                    item.type ===
                                                    "trigger_blockly"
                                                      ? [
                                                          _c(
                                                            "BlocklyTriggers",
                                                            {
                                                              attrs: {
                                                                triggerVarMap:
                                                                  _vm.triggerVarMap,
                                                                state:
                                                                  item.startState,
                                                                superGlobals: [].concat(
                                                                  _vm.superGlobalsModified.map(
                                                                    function (
                                                                      e
                                                                    ) {
                                                                      return (
                                                                        "@" +
                                                                        e.usage
                                                                      )
                                                                    }
                                                                  )
                                                                ),
                                                              },
                                                              on: {
                                                                valid: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.getBlocklyCode(
                                                                    $event,
                                                                    item,
                                                                    index
                                                                  )
                                                                },
                                                              },
                                                            }
                                                          ),
                                                        ]
                                                      : [
                                                          "campaign",
                                                          "adgroup",
                                                          "keyword",
                                                          "onetime",
                                                        ].includes(item.type)
                                                      ? [
                                                          _c("ActionEditor", {
                                                            staticClass: "pr-2",
                                                            attrs: {
                                                              validate:
                                                                _vm.validate[
                                                                  index
                                                                ],
                                                              insertFromNavigator:
                                                                item.insertFromNavigator,
                                                              template:
                                                                item.template,
                                                              scriptedArFunctions: _vm.getFunctionsList(
                                                                item.type
                                                              ),
                                                              scriptedArVars: _vm.getVariablesList(
                                                                item.type
                                                              ),
                                                              miscVars: _vm.getMiscVariableList(
                                                                item.type
                                                              ),
                                                              globalVars: _vm.globalVariablesMapIndexWise[
                                                                index
                                                              ].concat(
                                                                _vm.superGlobalsModified.map(
                                                                  function (e) {
                                                                    return e.usage
                                                                  }
                                                                )
                                                              ),
                                                              superGlobals: [].concat(
                                                                _vm.superGlobalsModified.map(
                                                                  function (e) {
                                                                    return e.usage
                                                                  }
                                                                )
                                                              ),
                                                              propCompletions: _vm.getPropList(
                                                                item.type
                                                              ),
                                                              type: item.type,
                                                            },
                                                            on: {
                                                              valid: function (
                                                                $event
                                                              ) {
                                                                return _vm.getEditorValues(
                                                                  $event,
                                                                  item,
                                                                  item.type,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      : [
                                                          "campaign_blockly",
                                                          "adgroup_blockly",
                                                          "keyword_blockly",
                                                          "onetime_blockly",
                                                        ].includes(item.type)
                                                      ? [
                                                          _c("BlocklyEditor", {
                                                            attrs: {
                                                              validate:
                                                                _vm.validate[
                                                                  index
                                                                ],
                                                              scriptedArFunctions: _vm.getFunctionsList(
                                                                item.type
                                                              ),
                                                              scriptedArVars: _vm.getVariablesList(
                                                                item.type
                                                              ),
                                                              scriptedSovArVars: _vm.getSovVariablesList(
                                                                item.type
                                                              ),
                                                              state:
                                                                item.startState,
                                                              globalVars: _vm.globalVariablesMapIndexWise[
                                                                index
                                                              ].concat(
                                                                _vm.superGlobalsModified.map(
                                                                  function (e) {
                                                                    return e.usage
                                                                  }
                                                                )
                                                              ),
                                                              superGlobals: [].concat(
                                                                _vm.superGlobalsModified.map(
                                                                  function (e) {
                                                                    return e.usage
                                                                  }
                                                                )
                                                              ),
                                                              type: item.type,
                                                              lookback:
                                                                _vm.settings
                                                                  .lookback,
                                                            },
                                                            on: {
                                                              valid: function (
                                                                $event
                                                              ) {
                                                                return _vm.getBlocklyCode(
                                                                  $event,
                                                                  item,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                                [
                                                  "campaign_blockly",
                                                  "adgroup_blockly",
                                                  "keyword_blockly",
                                                  "onetime_blockly",
                                                ].includes(item.type)
                                                  ? _c(
                                                      "v-card",
                                                      {
                                                        class: _vm.jsExpanded
                                                          ? ["blockly-editor"]
                                                          : [
                                                              "blockly-editor",
                                                              " ",
                                                            ],
                                                        attrs: {
                                                          elevation: "0",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-title",
                                                          {
                                                            staticClass:
                                                              "d-flex w-100 pa-2 justify-space-between  sticky-header-h ",
                                                          },
                                                          [
                                                            _vm.jsExpanded
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " mdi-arrow-right "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "h5",
                                                                      {
                                                                        staticClass:
                                                                          "primary--text mx-3",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "JS"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "grey--text caption--text ma-0 ",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Generated Javascript"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "cursor-pointer text-end",
                                                                                  attrs: {
                                                                                    color:
                                                                                      "primary",
                                                                                  },
                                                                                  on: {
                                                                                    click: function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.copyCode(
                                                                                        item.code
                                                                                      )
                                                                                    },
                                                                                  },
                                                                                },
                                                                                "v-icon",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " mdi-content-copy "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Copy"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm.blocklyCodePreview[
                                                          index
                                                        ]
                                                          ? _c("pre", [
                                                              _vm._v(
                                                                "                                      "
                                                              ),
                                                              _c(
                                                                "code",
                                                                {
                                                                  staticClass:
                                                                    "hljs javascript",
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm
                                                                          .blocklyCodePreview[
                                                                          index
                                                                        ]
                                                                      ),
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                "\n                                      "
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                ["trigger_blockly"].includes(
                                                  item.type
                                                )
                                                  ? _c(
                                                      "v-card",
                                                      {
                                                        class: _vm.jsExpanded
                                                          ? ["blockly-editor"]
                                                          : [
                                                              "blockly-editor",
                                                              "",
                                                            ],
                                                        attrs: {
                                                          elevation: "0",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-title",
                                                          {
                                                            staticClass:
                                                              "d-flex w-100 pa-2 justify-space-between  ",
                                                          },
                                                          [
                                                            _vm.jsExpanded
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " mdi-arrow-right "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "h5",
                                                                      {
                                                                        staticClass:
                                                                          "primary--text mx-3",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "JS"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "grey--text caption--text ma-0 ",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Generated SQL"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "cursor-pointer text-end",
                                                                                  attrs: {
                                                                                    color:
                                                                                      "primary",
                                                                                  },
                                                                                  on: {
                                                                                    click: function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.copyCode(
                                                                                        item.code
                                                                                      )
                                                                                    },
                                                                                  },
                                                                                },
                                                                                "v-icon",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " mdi-content-copy "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Copy"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm.blocklyCodePreview[
                                                          index
                                                        ]
                                                          ? _c("pre", [
                                                              _c(
                                                                "code",
                                                                {
                                                                  staticClass:
                                                                    "hljs sql",
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm
                                                                          .blocklyCodePreview[
                                                                          index
                                                                        ]
                                                                      ),
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-form",
                            {
                              ref: "sarSettings",
                              model: {
                                value: _vm.sarSettingsFormvalid,
                                callback: function ($$v) {
                                  _vm.sarSettingsFormvalid = $$v
                                },
                                expression: "sarSettingsFormvalid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "setting-sr mt-2 d-flex align-center br br-4 white pa-2 mx-0 mt-3",
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: {
                                        cols: "12",
                                        lg: "3",
                                        md: "3",
                                        sm: "6",
                                      },
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(_vm.$t("createAR.ruleName"))
                                        ),
                                      ]),
                                      _c("v-text-field", {
                                        staticClass: "v-sel-custom mt-0",
                                        attrs: {
                                          dense: "",
                                          rules: _vm.nameRule,
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.ruleName,
                                          callback: function ($$v) {
                                            _vm.ruleName = $$v
                                          },
                                          expression: "ruleName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: {
                                        cols: "12",
                                        lg: "3",
                                        md: "3",
                                        sm: "6",
                                      },
                                    },
                                    [
                                      _c("label", [_vm._v("Goals")]),
                                      _c(
                                        "v-form",
                                        {
                                          ref: "goalsFormRef",
                                          model: {
                                            value: _vm.goalFormvalid,
                                            callback: function ($$v) {
                                              _vm.goalFormvalid = $$v
                                            },
                                            expression: "goalFormvalid",
                                          },
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            ref: "goalsRef",
                                            staticClass: "rounded-lg",
                                            attrs: {
                                              items: _vm.appGoals || [],
                                              loading:
                                                _vm.$apollo.queries.getGoals
                                                  .loading,
                                              "item-text": "name",
                                              "item-value": "_id",
                                              required: "",
                                              dense: "",
                                              "prepend-inner-icon":
                                                "mdi-bullseye",
                                              "return-object": "",
                                              multiple: "",
                                              rules: _vm.goalRules,
                                            },
                                            on: { change: _vm.goalSelected },
                                            model: {
                                              value: _vm.selectedGoals,
                                              callback: function ($$v) {
                                                _vm.selectedGoals = $$v
                                              },
                                              expression: "selectedGoals",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: {
                                        cols: "12",
                                        lg: "3",
                                        md: "3",
                                        sm: "6",
                                      },
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("createAR.sharedVoiceLabel")
                                          )
                                        ),
                                      ]),
                                      _c("v-select", {
                                        staticClass: "v-sel-custom mt-0",
                                        attrs: {
                                          items: _vm.sharedVoiceOptions,
                                          rules: _vm.sovRules,
                                          dense: "",
                                          attach: "",
                                        },
                                        model: {
                                          value: _vm.selectShareVoiceOption,
                                          callback: function ($$v) {
                                            _vm.selectShareVoiceOption = $$v
                                          },
                                          expression: "selectShareVoiceOption",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: {
                                        cols: "12",
                                        lg: "3",
                                        md: "3",
                                        sm: "6",
                                      },
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("createAR.selectDateRange")
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "menu",
                                              attrs: {
                                                "close-on-content-click": false,
                                                "return-value":
                                                  _vm.ruleRangePicker,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "min-width": "auto",
                                              },
                                              on: {
                                                "update:returnValue": function (
                                                  $event
                                                ) {
                                                  _vm.ruleRangePicker = $event
                                                },
                                                "update:return-value": function (
                                                  $event
                                                ) {
                                                  _vm.ruleRangePicker = $event
                                                },
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-text-field",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                " w-sm-100 mx-0 pt-0",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "revert",
                                                              },
                                                              attrs: {
                                                                "prepend-inner-icon":
                                                                  "mdi-calendar",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.ruleDateRangeText,
                                                                callback: function (
                                                                  $$v
                                                                ) {
                                                                  _vm.ruleDateRangeText = $$v
                                                                },
                                                                expression:
                                                                  "ruleDateRangeText",
                                                              },
                                                            },
                                                            "v-text-field",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                              model: {
                                                value: _vm.ruleRangePicker,
                                                callback: function ($$v) {
                                                  _vm.ruleRangePicker = $$v
                                                },
                                                expression: "ruleRangePicker",
                                              },
                                            },
                                            [
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          flex: "auto",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-date-picker",
                                                          {
                                                            attrs: {
                                                              "no-title": "",
                                                              scrollable: "",
                                                              range: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleDateRangelModel,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.ruleDateRangelModel = $$v
                                                              },
                                                              expression:
                                                                "ruleDateRangelModel",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  text: "",
                                                                  color: "grey",
                                                                },
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    _vm.ruleRangePicker = false
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "createAR.cancel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.ruleDateRangelModel &&
                                                                    _vm
                                                                      .ruleDateRangelModel
                                                                      .length !==
                                                                      2,
                                                                  text: "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.applyRuleDateRange()
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "kai.ok"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  text: "",
                                                                  color:
                                                                    "secondary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.clearRuleDateRange,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "kai.clear"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0 pt-2",
                                      attrs: {
                                        cols: "12",
                                        lg: "3",
                                        md: "3",
                                        sm: "6",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "d-flex align-start" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-0",
                                              attrs: {
                                                cols: "6",
                                                lg: "6",
                                                md: "6",
                                                sm: "6",
                                              },
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("createAR.chckFreq")
                                                  )
                                                ),
                                              ]),
                                              _c("v-select", {
                                                staticClass:
                                                  "v-sel-custom mt-0",
                                                attrs: {
                                                  items: _vm.actionFreqs,
                                                  dense: "",
                                                  attach: "",
                                                  rules:
                                                    _vm.vSelectNotNullRules,
                                                },
                                                model: {
                                                  value:
                                                    _vm.settings.checkFrequency,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.settings,
                                                      "checkFrequency",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "settings.checkFrequency",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-0",
                                              attrs: {
                                                cols: "6",
                                                lg: "6",
                                                md: "6",
                                                sm: "6",
                                              },
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: " " },
                                                [_vm._v("Lookback (Days)")]
                                              ),
                                              _c("v-text-field", {
                                                staticClass:
                                                  "v-sel-custom mt-0",
                                                attrs: {
                                                  dense: "",
                                                  rules: _vm.lookbackRule,
                                                  required: "",
                                                  type: "number",
                                                  max: "30",
                                                  min: "0",
                                                },
                                                model: {
                                                  value: _vm.settings.lookback,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.settings,
                                                      "lookback",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "settings.lookback",
                                                },
                                              }),
                                              _vm.settings.lookback == 0
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "primarydark--text",
                                                      attrs: {
                                                        color: "secondary",
                                                        "text-color": "white",
                                                      },
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          "Current day only"
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: {
                                        cols: "12",
                                        lg: "3",
                                        md: "3",
                                        sm: "12",
                                      },
                                    },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "my-0",
                                        attrs: {
                                          height: "0",
                                          label: "Send Reports",
                                        },
                                        model: {
                                          value: _vm.settings.sendReports,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.settings,
                                              "sendReports",
                                              $$v
                                            )
                                          },
                                          expression: "settings.sendReports",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: {
                                        cols: "12",
                                        lg: "3",
                                        md: "3",
                                        sm: "12",
                                      },
                                    },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "my-0",
                                        attrs: {
                                          height: "0",
                                          label:
                                            "Include paused or inactive entities.",
                                        },
                                        model: {
                                          value: _vm.includeInactiveEntities,
                                          callback: function ($$v) {
                                            _vm.includeInactiveEntities = $$v
                                          },
                                          expression: "includeInactiveEntities",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.settings.sendReports
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "setting-sr mt-2 d-flex align-center br br-4 white pa-2 mx-0 mt-6",
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-0",
                                          attrs: {
                                            cols: "6",
                                            lg: "3",
                                            md: "3",
                                            sm: "6",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "field" },
                                            [
                                              _c("v-select", {
                                                staticClass:
                                                  "v-sel-custom v-sel-custom-rule",
                                                attrs: {
                                                  items: _vm.emailList,
                                                  "item-text": "Email",
                                                  "item-value": "Email",
                                                  label: _vm.$t(
                                                    "createAR.selectEmail"
                                                  ),
                                                  multiple: "",
                                                  attach: "",
                                                  chips: "",
                                                  dense: "",
                                                  rules: _vm.vSelectEmailRules,
                                                  disabled: !_vm.settings
                                                    .sendReports,
                                                  loading:
                                                    _vm.$apollo.queries
                                                      .getEmails.loading,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.emailSelectionChanged()
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    _vm.emailList.length > 0
                                                      ? {
                                                          key: "prepend-item",
                                                          fn: function () {
                                                            return [
                                                              _c("v-divider", {
                                                                staticClass:
                                                                  "mt-2",
                                                              }),
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  attrs: {
                                                                    ripple: "",
                                                                  },
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.toggleEmailSelectAll()
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-action",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "primary",
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.selectAllEmailIcon
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "createAR.selectAll"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("v-divider", {
                                                                staticClass:
                                                                  "mt-2",
                                                              }),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        }
                                                      : null,
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.selectedMailIds,
                                                  callback: function ($$v) {
                                                    _vm.selectedMailIds = $$v
                                                  },
                                                  expression: "selectedMailIds",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-0",
                                          attrs: {
                                            cols: "6",
                                            lg: "3",
                                            md: "3",
                                            sm: "6",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "field" },
                                            [
                                              _c("v-select", {
                                                staticClass: "v-sel-custom",
                                                attrs: {
                                                  items: _vm.slackChannelList,
                                                  label: _vm.$t(
                                                    "createAR.selectChannel"
                                                  ),
                                                  "no-data-text": _vm.$t(
                                                    "createAR.noDataAvailable"
                                                  ),
                                                  "item-value": "IntegrationID",
                                                  "item-text": "channel",
                                                  attach: "",
                                                  chips: "",
                                                  dense: "",
                                                  multiple: "",
                                                  rules: _vm.vSelectRules,
                                                  disabled: !_vm.settings
                                                    .sendReports,
                                                  loading:
                                                    _vm.$apollo.queries
                                                      .getSlacks.loading,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.slackSelectionChanged()
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    _vm.slackChannelList
                                                      .length > 0
                                                      ? {
                                                          key: "prepend-item",
                                                          fn: function () {
                                                            return [
                                                              _c("v-divider", {
                                                                staticClass:
                                                                  "mt-2",
                                                              }),
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  attrs: {
                                                                    ripple: "",
                                                                  },
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.toggleSlackSelectAll()
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-action",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "primary",
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.selectAllSlackIcon
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "createAR.selectAll"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("v-divider", {
                                                                staticClass:
                                                                  "mt-2",
                                                              }),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        }
                                                      : null,
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.selectedSlacks,
                                                  callback: function ($$v) {
                                                    _vm.selectedSlacks = $$v
                                                  },
                                                  expression: "selectedSlacks",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-0",
                                          attrs: {
                                            cols: "12",
                                            lg: "6",
                                            md: "6",
                                            sm: "12",
                                          },
                                        },
                                        [
                                          _c("v-combobox", {
                                            ref: "emailSelectRef",
                                            staticClass: "v-sel-custom",
                                            attrs: {
                                              multiple: "",
                                              label: "Enter other Email Ids",
                                              chips: "",
                                              "deletable-chips": "",
                                              dense: "",
                                              rules: _vm.csvEmailRules,
                                              disabled: !_vm.settings
                                                .sendReports,
                                            },
                                            on: {
                                              change: _vm.onModelValueChange,
                                            },
                                            model: {
                                              value: _vm.externalMailIds,
                                              callback: function ($$v) {
                                                _vm.externalMailIds = $$v
                                              },
                                              expression: "externalMailIds",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "d-flex justify-end align-center mb-2 mt-4",
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-0 checkbox-container mb-3",
                                  attrs: {
                                    cols: "12",
                                    lg: "8",
                                    md: "8",
                                    sm: "12",
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        " primary--text  grey--text mb-0 ",
                                    },
                                    [
                                      _vm._v("Please review the "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "text-decoration-underline",
                                          attrs: {
                                            href:
                                              "https://support.searchadsmaven.com/docs/r9to5oq9ad",
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v("Quick Start Guide")]
                                      ),
                                      _vm._v(
                                        " before creating your first rule. "
                                      ),
                                      _c("br"),
                                      _vm._v(" We can help! Contact "),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "mailto:support@searchadsmaven.com",
                                          },
                                        },
                                        [_vm._v("support@searchadsmaven.com")]
                                      ),
                                      _vm._v(
                                        " for assistance with rule creation."
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-end",
                                  attrs: {
                                    cols: "12",
                                    lg: "4",
                                    md: "4",
                                    sm: "12",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      !_vm.editModeSAR
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-3",
                                              attrs: {
                                                width: "127",
                                                color: "primary",
                                                depressed: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.initiateSaveSarAction(
                                                    4,
                                                    {}
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Save ")]
                                          )
                                        : _vm._e(),
                                      _vm.editModeSAR
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                width: "127",
                                                color: "primary",
                                                depressed: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.initiateUpdateSarAction(
                                                    5,
                                                    {}
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Update ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", width: "600" },
                      model: {
                        value: _vm.validProgDialogVisible,
                        callback: function ($$v) {
                          _vm.validProgDialogVisible = $$v
                        },
                        expression: "validProgDialogVisible",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100",
                            },
                            [_c("h5", [_vm._v("Validation")])]
                          ),
                          _c(
                            "v-card-text",
                            {
                              staticClass:
                                "d-flex align-start px-6 py-8 pb-6 w-100",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-column w-100" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "text-subtitle-1 pa-0" },
                                        [
                                          _vm._v(
                                            " Validation in progress, please wait... "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", width: "600" },
                      model: {
                        value: _vm.dialogVisible,
                        callback: function ($$v) {
                          _vm.dialogVisible = $$v
                        },
                        expression: "dialogVisible",
                      },
                    },
                    [
                      _vm.validationError
                        ? _c(
                            "v-card",
                            {
                              staticClass:
                                "d-flex align-center justify-center flex-column w-100",
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100",
                                },
                                [
                                  _c("h5", [_vm._v("Validation failed")]),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "white--text",
                                      on: { click: _vm.closeDialog },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "d-flex align-start px-6 py-8 pb-6 w-100",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column w-100" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex" },
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass:
                                                "text-subtitle-1 pa-0",
                                            },
                                            [
                                              _vm._v(
                                                " The following errors were found: "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "red--text font-22 ml-1",
                                            },
                                            [_vm._v("mdi-alert-outline")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            " text-subtitle-1 pa-0 d-flex w-100 flex-column",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "scroll-auto error-height",
                                            },
                                            [
                                              _vm._l(
                                                _vm.dialogErrorMessage,
                                                function (item, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key:
                                                        index +
                                                        "-dialogErrorMessage",
                                                    },
                                                    [
                                                      _c("p", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _vm._l(
                                                _vm.ruleErrorsArray,
                                                function (item, index) {
                                                  return _c(
                                                    "v-list",
                                                    {
                                                      key:
                                                        index +
                                                        "-ruleErrorsArray",
                                                      staticClass: "pa-0",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        { staticClass: "mb-0" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.error) +
                                                              " "
                                                          ),
                                                          _c("ul", [
                                                            Array.isArray(
                                                              item.message
                                                            )
                                                              ? _c(
                                                                  "li",
                                                                  _vm._l(
                                                                    item.message,
                                                                    function (
                                                                      msg,
                                                                      msgIndex
                                                                    ) {
                                                                      return _c(
                                                                        "p",
                                                                        {
                                                                          key:
                                                                            msgIndex +
                                                                            "-item-message",
                                                                          staticClass:
                                                                            "mb-0",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                msg.msgErrDesc
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              : _c("li", [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "mb-0",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.message
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-card-actions",
                                {
                                  staticClass:
                                    "d-flex justify-end align-center w-100 pb-6  px-6",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.closeDialog },
                                    },
                                    [_vm._v("Close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100",
                                },
                                [
                                  _c("h5", [_vm._v("Validate")]),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "white--text",
                                      on: { click: _vm.closeDialog },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                              _c("v-card-title", [
                                _vm._v(" Validation Complete "),
                              ]),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "success" } },
                                    [_vm._v("mdi-check")]
                                  ),
                                  _c("span", [_vm._v("No errors found")]),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                {
                                  staticClass:
                                    "d-flex justify-end align-center w-100 pb-6  px-6",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.closeDialog },
                                    },
                                    [_vm._v("Close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c("DecisionAlert", {
                    attrs: {
                      DecisionAlert: _vm.decisionAlertFlag,
                      DecisionAlertTitle: _vm.decisionAlertTitle,
                      DecisionAlertIcon: _vm.decisionAlertIcon,
                      DecisionAlertMessage: _vm.decisionAlertMessage,
                      DecisionAlertData: _vm.decisionAlertData,
                    },
                    on: {
                      emitDecisionAlertCancelClicked: _vm.clearDecisionModel,
                      emitDecisionAlertOkClicked: function ($event) {
                        return _vm.decisionOkAction($event)
                      },
                    },
                  }),
                  _c("BlocklyJsSwitch", {
                    attrs: {
                      DecisionAlert: _vm.blocklyJsSwitchFlag,
                      DecisionAlertTitle: _vm.blocklyJsSwitchTitle,
                      DecisionAlertIcon: _vm.blocklyJsSwitchIcon,
                      onlyMessage: _vm.blocklyJsSwitchOnlyMessage,
                      DecisionAlertMessage: _vm.blocklyJsSwitchMessage,
                      DecisionAlertData: _vm.blocklyJsSwitchData,
                    },
                    on: {
                      emitDecisionAlertCancelClicked: _vm.clearDecisionModel,
                      emitDecisionAlertOkClicked: function ($event) {
                        return _vm.decisionOkAction($event)
                      },
                    },
                  }),
                ],
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }